import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next';

// Components
import TournamentFAQ from 'components/tournament_details/TournamentFAQ';
import TournamentTeamList from 'components/tournament_details/TournamentTeamList';
import TournamentTimeline from 'components/tournament_details/TournamentTimeline';
import TournamentRegistrationForm from 'components/tournament_details/TournamentRegistrationForm';

// Apis
import useFetch from 'utils/hooks/useFetch'
import { GET_TOURNAMENT, GET_TOURNAMENT_SCHEDULE } from 'api';

// Data
import tournamentDetails from '../assets/data/examples/tournament.details.ex.json'

function TournamentDetails() {
  const { data: tournament, fetch: getTournament } = useFetch(GET_TOURNAMENT)
  const { data: schedules, fetch: getTournamentSchedules } = useFetch(GET_TOURNAMENT_SCHEDULE)

  const { t } = useTranslation();
  const { id } = useParams();

  const [isRegistrationFormOpen, setIsRegistrationFormOpen] = useState(false);

  useEffect(() => {
    getTournament({
      params: {
        id
      }
    })
  }, [
    getTournament,
    id
  ])

  useEffect(() => {
    if (tournament && tournament.id) {
      getTournamentSchedules({
        params: {
          tournament_id: tournament.id
        },
        data: {
          sort: ['id', 'ASC'],
        }
      })
    }
  }, [
    tournament,
    getTournamentSchedules
  ])

  return (
    <div className='pb-8 bg-center bg-[url("https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/1edf2a8833ec42d9dfa27756284e6ef6413883cdb62e3a07e2e43faaf1cdaad25124dbafd033c0257bc59c86af3982c3439c848e633c83924be7cc30d531dc8dc255bfaf471f60630ed8eb0e89e3efda8e66866fe0029a816a553c3acdfe82ed5dc37715d92ebf699fce925bd6f4085199bc4ba1e0f275d3cb1c/file-1722086703913.png")]'>

      <section>
        <div className='relative z-20'>
          <img src={'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/de5b51d222d5c1b4ae0877a19e12ca7d144ea70b6988e3939b1a9773cd19769eb821d4535bef33800eb9feccd92541a09b731209a10c798c2f6dd5f711d00b9ab46b9e7c83f23e18f52c4f0d53e8191420af26bd1780d0bd3c9e29094c276c86ab2c5bf423a8656fad54a28bc3601cf287210ec5da7e43e3baa6/file-1721727345291.png'} alt="main" className='w-full object-contain object-center' />
        </div>
      </section>

      <img className='top-24 left-0 opacity-20 fixed w-48 sm:w-96' src="https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/a52572302f6436f86b4228f14d080dc06858967e8377a9b52309afb31a0b6e50b379b4f7c01266e5a2173eae3fdb16d897ad4c0a536d81f929e49c84036b9045a070d81a18e60d208012c2719a07d6b28814e128f343eb0cfd145fa8d27e0a6b206c7b03be806fb6cdac584b9d93345b73f59fc3932d5a057e3e/file-1722094294672.png" />
      <img className='bottom-0 right-0 opacity-20 fixed w-48 sm:w-96' src="https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/e68a8458d6ee96b81b7bb69ff37534e21689dabe0ba8e48ccc35bd0663da2bd78486289e85d706750ffbb60968a6aea6178b4aa8546a7f5394bca83455749b68d27a1318b47539e23b6025d261589b3575bbf867bbc8c0cbfec97fc790129acfeda865eefdacd624e2dbab78903219b322bbaf3086c216a55549/file-1722095872287.png" />

      <section>
        <div className='max-w-7xl mx-auto px-4 py-6 md:px-12 md:py-8 space-y-8'>
          <p className='text-center text-xl sm:text-3xl font-bold app-animation-translate-start'>
            {tournament && tournament.title}
          </p>
          <p className='text-center text-sm sm:text-base mx-auto app-animation-translate-start' dangerouslySetInnerHTML={{ __html: tournament && tournament.description }} />
        </div>
      </section>

      <section >
        <div className='max-w-7xl mx-auto px-4 py-6 sm:px-12 sm:py-8 space-y-8 app-animation-scale-start'>
          <img src={'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/507f34d67c571fb4ce8d8eb431f7c6e5c463364982689cd8320fef49abf9a325ed3759595d40528f76a87f611707d70243e10cfd2a23fd986cd134045affe1545903727d564ac56520fc8c9b8d2a87e40c6261f1a788d12ba02818ed9ff040e4fafdab836368c60408d13c69b5d86f275d3d27a8e468b3da4f02/file-1721815146420.png'} alt="main" className='w-full object-contain object-center' />
        </div>
      </section>

      <TournamentTimeline selectedTournamentTimelines={schedules} />

      <section>
        <div className='app-main-container mx-auto space-y-8 app-animation-translate-start'>
          <div className='text-center'>
            {isRegistrationFormOpen ? (
              <TournamentRegistrationForm id={id} setIsRegistrationFormOpen={setIsRegistrationFormOpen} schedules={schedules} />
            ) : (
              <button onClick={() => setIsRegistrationFormOpen(!isRegistrationFormOpen)} className="uppercase text-sm sm:text-base bg-red-500 hover:bg-red-700 text-white font-bold py-4 px-8 rounded-full" >
                {t('registerNow')}
              </button>
            )}
          </div>
          <div className='text-center'>
            <a href='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/868abf16231c246adb2cf71449f7de80ca1ccd5d98cba5f9fde4071ccddd16e13aa2a139523e001b8f9229aea03407f5074f1b1e7429f7c6de8fc4cc17f5551c8026bfa90c0f1517db1e2b2d46fe57e4ab0221efd7887391377b411fe7824c1ac81a0eb9aa61690d587a347fa21d00391992f9b964321162cd14/file-1722243134139.pdf' target="_blank" rel="noopener noreferrer" className="bg-transparent uppercase text-sm sm:text-base hover:bg-gray-200 font-semibold py-2 px-8 border border-black border-2 rounded-full cursor-pointer">
              {t('rulesBook')}
            </a>
          </div>
        </div>
      </section >

      <section >
        <div className='max-w-7xl mx-auto px-4 py-6 sm:px-12 sm:py-8 space-y-8 app-animation-scale-start'>
          <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSeEaFaZf9_dTv_6Mzk66dZBSRF-hWMkDbbIr9wLcpndpQUsNA/viewform">
            <img src={'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/af5b7ca22fab666455c70e6e543ccd77f9ce02b33c87642eb16f43b889be82fe14dd8f8958a2c5f93377a14b002ad0fd7ad65caca75aa0b3d8376e0ff1054f2c334df398b5139800129b3b2cd93a8954b21f7eb38542206e699b0b9bf4a372bed16ba3566ef851171617a40537033cd24a8e09ed69374f5494ca/file-1723620522953.png'} alt="main" className='w-full object-contain object-center' />
          </a>
        </div>
      </section>

      <TournamentTeamList tournament={tournament} schedules={schedules} />

      <TournamentFAQ />

    </div >
  )
}

export default TournamentDetails