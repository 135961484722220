import React from 'react'
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';

// Icon
import KeyboardArrowDownIcon from 'assets/icons/KeyboardArrowIcon';

// Components
import TournamentPlayerForm from './TournamentPlayerForm';

function TournamentPlayerListForm({
  value,
  error,
  onChange,
  handleIdentityFile
}) {
  const { t } = useTranslation();

  const AccordionHeading = ({ heading }) => {
    return (
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionItemState>
            {({ expanded }) => (
              <div className={`flex flex-row p-4 justify-between items-center border-b-2 border-gray hover:bg-gray-200 ${expanded && "bg-gray-200"}`}>
                <div>
                  <p className='text-sm sm:text-base font-bold'>
                    {heading}
                  </p>
                </div>
                <div>
                  <KeyboardArrowDownIcon
                    className={`h-3 sm:h-4 lg:h-5 w-3 sm:w-4 lg:w-5 fill-black ml-auto transition-transform duration-200 ease-in-out ${expanded && "rotate-180"
                      }`}
                  />
                </div>
              </div>)}
          </AccordionItemState>
        </AccordionItemButton>
      </AccordionItemHeading>
    )
  }

  return (
    <section>
      <div className='pt-3 space-y-8'>
        <p className='text-center text-center text-lg md:text-xl xl:text-2xl font-bold'>
          {t('playerList')}
        </p>
        <Accordion preExpanded={[1]} allowZeroExpanded className='transition-all duration-200'>
          <AccordionItem uuid={1}>
            <AccordionHeading heading={t('player1')} />
            <AccordionItemPanel className='p-4 transition-height duration-200 ease-in-out'>
              <TournamentPlayerForm
                textInputName1={'name_player_1'}
                valueInput1={value.name_player_1}
                errorInput1={error.name_player_1}
                textInputName2={'nickname_uid_player_1'}
                valueInput2={value.nickname_uid_player_1}
                errorInput2={error.nickname_uid_player_1}
                selectName={'role_player_1'}
                valueSelectInput={value.role_player_1}
                errorSelectInput={error.role_player_1}
                fileInputName={'identity_file_player_1'}
                valueFileInput={value.identity_file_player_1}
                errorFileInput={error.identity_file_player_1}
                required={true}
                onChangeInput={onChange}
                onChangeFileInput={handleIdentityFile}
              />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid={2}>
            <AccordionHeading heading={t('player2')} />
            <AccordionItemPanel className='p-4 transition-height duration-200 ease-in-out'>
              <TournamentPlayerForm
                textInputName1={'name_player_2'}
                valueInput1={value.name_player_2}
                errorInput1={error.name_player_2}
                textInputName2={'nickname_uid_player_2'}
                valueInput2={value.nickname_uid_player_2}
                errorInput2={error.nickname_uid_player_2}
                selectName={'role_player_2'}
                valueSelectInput={value.role_player_2}
                errorSelectInput={error.role_player_2}
                fileInputName={'identity_file_player_2'}
                valueFileInput={value.identity_file_player_2}
                errorFileInput={error.identity_file_player_2}
                required={true}
                onChangeInput={onChange}
                onChangeFileInput={handleIdentityFile}
              />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid={3}>
            <AccordionHeading heading={t('player3')} />
            <AccordionItemPanel className='p-4 transition-height duration-200 ease-in-out'>
              <TournamentPlayerForm
                textInputName1={'name_player_3'}
                valueInput1={value.name_player_3}
                errorInput1={error.name_player_3}
                textInputName2={'nickname_uid_player_3'}
                valueInput2={value.nickname_uid_player_3}
                errorInput2={error.nickname_uid_player_3}
                selectName={'role_player_3'}
                valueSelectInput={value.role_player_3}
                errorSelectInput={error.role_player_3}
                fileInputName={'identity_file_player_3'}
                valueFileInput={value.identity_file_player_3}
                errorFileInput={error.identity_file_player_3}
                required={true}
                onChangeInput={onChange}
                onChangeFileInput={handleIdentityFile}
              />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid={4}>
            <AccordionHeading heading={t('player4')} />
            <AccordionItemPanel className='p-4 transition-height duration-200 ease-in-out'>
              <TournamentPlayerForm
                textInputName1={'name_player_4'}
                valueInput1={value.name_player_4}
                errorInput1={error.name_player_4}
                textInputName2={'nickname_uid_player_4'}
                valueInput2={value.nickname_uid_player_4}
                errorInput2={error.nickname_uid_player_4}
                selectName={'role_player_4'}
                valueSelectInput={value.role_player_4}
                errorSelectInput={error.role_player_4}
                fileInputName={'identity_file_player_4'}
                valueFileInput={value.identity_file_player_4}
                errorFileInput={error.identity_file_player_4}
                required={true}
                onChangeInput={onChange}
                onChangeFileInput={handleIdentityFile}
              />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid={5}>
            <AccordionHeading heading={t('player5')} />
            <AccordionItemPanel className='p-4 transition-height duration-200 ease-in-out'>
              <TournamentPlayerForm
                textInputName1={'name_player_5'}
                valueInput1={value.name_player_5}
                errorInput1={error.name_player_5}
                textInputName2={'nickname_uid_player_5'}
                valueInput2={value.nickname_uid_player_5}
                errorInput2={error.nickname_uid_player_5}
                selectName={'role_player_5'}
                valueSelectInput={value.role_player_5}
                errorSelectInput={error.role_player_5}
                fileInputName={'identity_file_player_5'}
                valueFileInput={value.identity_file_player_5}
                errorFileInput={error.identity_file_player_5}
                required={true}
                onChangeInput={onChange}
                onChangeFileInput={handleIdentityFile}
              />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid={6}>
            <AccordionHeading heading={t('reservePlayer')} />
            <AccordionItemPanel className='p-4 transition-height duration-200 ease-in-out'>
              <TournamentPlayerForm
                textInputName1={'name_reserve_player'}
                valueInput1={value.name_reserve_player}
                errorInput1={error.name_reserve_player}
                textInputName2={'nickname_uid_reserve_player'}
                valueInput2={value.nickname_uid_reserve_player}
                errorInput2={error.nickname_uid_reserve_player}
                selectName={'role_reserve_player'}
                valueSelectInput={value.role_reserve_player}
                errorSelectInput={error.role_reserve_player}
                fileInputName={'identity_file_reserve_player'}
                valueFileInput={value.identity_file_reserve_player}
                errorFileInput={error.identity_file_reserve_player}
                required={false}
                onChangeInput={onChange}
                onChangeFileInput={handleIdentityFile}
              />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </section>
  )
}

export default TournamentPlayerListForm