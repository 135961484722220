import axios from 'axios'
import { API_BASE_URL } from 'utils/constants/api'

const baseURL = API_BASE_URL

const api = axios.create({
    baseURL,
    withCredentials: true
})

export default api;

export const GET_ALL_PUBLIC_POSTS = {
    method: 'get',
    url: "/api/blog/v1/blogs/public"
}

export const GET_POST_DETAILS = {
    method: 'get',
    url: "/api/blog/v1/blogs/:id"
}

export const SEND_CONTACT_FORM = {
    method: "post",
    url: "/api/blog/v1/email/contact-us"
}

export const GET_ALL_TOURNAMENTS = {
    method: 'post',
    url: "/api/blog/v1/tournaments"
}

export const GET_TOURNAMENT = {
    method: 'get',
    url: "/api/blog/v1/tournaments/:id"
}

export const GET_TOURNAMENT_SCHEDULE = {
    method: 'post',
    url: "/api/blog/v1/tournaments/schedule/:tournament_id"
}

export const GET_TOURNAMENT_PARTICIPANTS = {
    method: 'post',
    url: "/api/blog/v1/tournaments/registration/public/:tournament_id"
}

export const CREATE_REGISTRATION_TOURNAMENTS = {
    method: 'post',
    url: "/api/blog/v1/tournaments/registration/create"
}